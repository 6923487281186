import React, { useState, useEffect } from 'react';
import {FormRow, FormSection, FormWrapper, InputGroup, Label, TextArea, Input} from "../../../../common/molecules/Form";
import { getLocalTime } from '../../../../../utils/flight-utils';
import styled from 'styled-components';
import TimeField from "react-simple-timefield";

const TableContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  th, td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #f2f2f2;
    text-align: left;
    font-weight: bold;
  }
  
  td:first-child {
    width: 75%;
  }
  
  td:last-child {
    width: 25%;
  }
`;

const CommentsReport = ({comments, ptsAddedEvents, timezoneOffset, onCommentsUpdate, onBoardingCommentsUpdate}) => {
  // Simple state for boarding comments as plain text
  const [boardingCommentsText, setBoardingCommentsText] = useState('');
  
  // Initialize from ptsAddedEvents, but just as a one-time setup
  useEffect(() => {
    // If ptsAddedEvents is a string already, use it directly
    if (typeof ptsAddedEvents === 'string') {
      setBoardingCommentsText(ptsAddedEvents);
    } 
    // Otherwise if it's an array, format it once for initial display
    else if (Array.isArray(ptsAddedEvents)) {
      const formatted = ptsAddedEvents.map(e => 
        `${getLocalTime(e.createAt, timezoneOffset)}, ${e.content}`
      ).join('\r\n');
      setBoardingCommentsText(formatted);
    } else {
      setBoardingCommentsText('');
    }
  }, [ptsAddedEvents, timezoneOffset]);
  
  // Initialize with empty delay reasons or parse from comments if available
  const [delayReasons, setDelayReasons] = useState([
    { reason: '', time: '00:00' },
    { reason: '', time: '00:00' },
    { reason: '', time: '00:00' },
    { reason: '', time: '00:00' }
  ]);
  
  // On mount, try to parse existing comments to populate the delay reasons
  useEffect(() => {
    if (comments) {
      try {
        let parsedComments;
        
        // Handle both array and object formats for backward compatibility
        if (comments.startsWith('[')) {
          // Comments is an array of delay reasons
          parsedComments = JSON.parse(comments);
        } else if (comments.startsWith('{')) {
          // Comments might be a single delay reason object
          const singleReason = JSON.parse(comments);
          parsedComments = singleReason.reason !== undefined ? [singleReason] : [];
        } else {
          // Comments is a string, not JSON
          parsedComments = [];
        }
        
        if (Array.isArray(parsedComments)) {
          // Always ensure we have exactly 4 entries in the UI
          const updatedReasons = [
            { reason: '', time: '00:00' },
            { reason: '', time: '00:00' },
            { reason: '', time: '00:00' },
            { reason: '', time: '00:00' }
          ];
          
          // Copy over the existing reasons (up to 4)
          parsedComments.forEach((item, index) => {
            if (index < 4 && item && (item.reason !== undefined || item.time !== undefined)) {
              updatedReasons[index] = {
                reason: item.reason || '',
                time: item.time || '00:00'
              };
            }
          });
          
          setDelayReasons(updatedReasons);
        }
      } catch (e) {
        // If not JSON or parsing fails, keep the default empty state
        console.log('Could not parse existing comments as JSON:', e);
      }
    }
  }, [comments]);
  
  // Update delay reason
  const updateDelayReason = (index, field, value) => {
    const updatedReasons = [...delayReasons];
    updatedReasons[index] = {
      ...updatedReasons[index],
      [field]: value
    };
    setDelayReasons(updatedReasons);
    
    // Filter out empty reasons before saving (reason and time both empty)
    const nonEmptyReasons = updatedReasons.filter(
      item => item.reason.trim() !== '' || (item.time !== '00:00' && item.time.trim() !== '')
    );
    
    // If all reasons are empty, save at least one empty reason to maintain structure
    const reasonsToSave = nonEmptyReasons.length > 0 ? nonEmptyReasons : [{ reason: '', time: '00:00' }];
    
    // Create JSON string for the comments
    const jsonStr = JSON.stringify(reasonsToSave);
    
    // Update the comments with the JSON string of the delay reasons
    onCommentsUpdate(jsonStr);
  };
  
  return (
    <FormWrapper>
      <FormSection>
        <FormRow>
          <InputGroup>
            <Label>Boarding Comments</Label>
            <TextArea 
              value={boardingCommentsText} 
              onChange={(e) => {
                const newValue = e.target.value;
                setBoardingCommentsText(newValue);
                
                // Simply pass the raw text value to the parent component
                if (onBoardingCommentsUpdate) {
                  onBoardingCommentsUpdate(newValue);
                }
              }}
              placeholder="Enter boarding comments"
            />
          </InputGroup>
        </FormRow>
        <FormRow>
          <InputGroup>
            <Label>Delay Reasons</Label>
            <TableContainer>
              <StyledTable>
                <thead>
                  <tr>
                    <th>Delay Reasons</th>
                    <th>Delay Length</th>
                  </tr>
                </thead>
                <tbody>
                  {delayReasons.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Input
                          value={item.reason}
                          onChange={(e) => updateDelayReason(index, 'reason', e.target.value)}
                          placeholder="Enter delay reason"
                          style={{ width: '100%' }}
                        />
                      </td>
                      <td>
                        <TimeField
                          value={item.time}
                          onChange={(_, timeString) => updateDelayReason(index, 'time', timeString)}
                          input={<Input style={{ width: '80px' }} />}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </TableContainer>
          </InputGroup>
        </FormRow>
      </FormSection>
    </FormWrapper>
  );
};

export default CommentsReport;
