import styled, {css} from 'styled-components';
import FlexSection from "../../../../../common/atoms/FlexSection";
import Button from "../../../../../common/atoms/Button";
import {Input} from "../../../../../common/molecules/Form/index.js"
import device from "../../../../../../constants/Device";

export const Editor = styled(FlexSection)`
  flex-direction: column;

  width: 460px;

  background: white;

  padding: ${props => props.theme.space5};
`;

export const Timer = styled(FlexSection)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimerButton = styled(Button).attrs({basic: 'true'})`
  /* Increase hit area while maintaining visual size */
  padding: ${props => props.theme.space4};
  min-width: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Ensure the SVG icon stays the same size */
  svg {
    width: 16px;
    height: 16px;
  }

  ${props => props.disabled && css`
    svg {
      fill: ${props => props.theme.colors.lightGrey};
    }
  `};

  ${props => !props.disabled && css`
    svg {
      fill: ${props => props.theme.colors.body};
    }
  `};
`;

export const TimeInput = styled(Input)`

  padding: ${props => props.theme.space3} ${props => props.theme.space4};
  margin: ${props => props.theme.space3};
  text-align: center;

  font-family: ${props => props.theme.fontRegular};
  font-size: ${props => props.theme.size9};

  color: ${props => props.theme.colors.body};

  /* Mobile Responsive */
  width: 160px;
  @media ${device.tablet} {
    width: 160px;
  }
`;
