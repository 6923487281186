import styled, {css} from 'styled-components';

export const RadioButton = styled.span`
  /* Display */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${props => props.theme.size10};
  height: ${props => props.theme.size8};
  width: auto;

  /* Spacing */
  margin-top: ${props => props.theme.space2};
  margin-left: ${props => props.theme.space2};

  /* Border */
  border-radius: ${props => props.theme.cornerRadius};
  box-shadow: 0 ${props => props.theme.sizeExtraSmall} ${props => props.theme.size1} 0 rgba(0,0,0,0.15);
  padding: ${props => props.theme.space2} ${props => props.theme.space3};

  /* Text handling */
  white-space: nowrap;
  text-align: center;

  /* Cursor styling */
  cursor: pointer;
  text-transform: uppercase;
  user-select: none;

  color: ${props => props.theme.colors.grey};
  svg {
    fill: ${props => props.theme.colors.grey};
  }

  :hover {
    color: ${props => props.theme.colors.black};
    svg {
      fill: ${props => props.theme.colors.black};
    }
  }

  ${props => props.selected && css`
    background: ${props => props.theme.colors.black};

    color: white;
    svg {
      fill: white;
    }

    :hover {
      color: ${props => props.theme.colors.grey};
      svg {
        fill: ${props => props.theme.colors.grey};
      }
    }
  `}
`;