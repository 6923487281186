import styled from 'styled-components'
import PropTypes from 'prop-types';

const FlexSection = styled.div`
  display: flex;
  align-items: center;
  ${props => props.vertical && `
    flex-direction: column;
    align-items: flex-start;

    & > span {
      margin-left: 0;
      margin-top: ${props => props.theme.space2};
    }
  `}
`;

FlexSection.propTypes = {
  children: PropTypes.node.isRequired,
  vertical: PropTypes.bool,
};

export default FlexSection;
