import React from 'react';
import PropTypes from 'prop-types';

import {RadioButton} from './style';
import FlexSection from '../../atoms/FlexSection';

const RadioSelect = ({label, options, onChange, vertical}) => {
  return (
    <FlexSection vertical={vertical}>
      {label}
      {options.map(option => (
        <RadioButton
          key={option.label}
          selected={option.selected}
          onClick={() => {
            // Create new options with only one selected
            const newOptions = options.map(o => ({
              ...o,
              selected: o.value === option.value // Only select the clicked one
            }));
            onChange(newOptions);
          }}
        >
          {option.label}
        </RadioButton>
      ))}
    </FlexSection>
  )
};

RadioSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    value: PropTypes.any.isRequired,
    selected: PropTypes.bool
  })),
  onChange: PropTypes.func.isRequired,
  vertical: PropTypes.bool,
};

export default RadioSelect;
