import React, {Component} from 'react';
import {FormRow, FormSection, FormWrapper, Input, InputGroup, Label} from "../../../../common/molecules/Form";
import Checkbox from "../../../../common/atoms/Checkbox";
import TimeField from "react-simple-timefield";
import RadioSelect from "../../../../common/molecules/RadioSelect";

class PreDepartureReport extends Component {

  constructor(props) {
    super(props);

    const { preDeparture } = props;

    this.state = {
      checked: {
        aircraft_visual_reg_check: !!preDeparture.aircraft_visual_reg_check,
        seat_map_exit_rows: !!preDeparture.seat_map_exit_rows,
        zones_correct: !!preDeparture.zones_correct,
        mandatory_briefing: !!preDeparture.mandatory_briefing,
        rego_check: !!preDeparture.rego_check,
        ats_event_reviewed: !!preDeparture.ats_event_reviewed,
        seating_requirement_reviewed: !!preDeparture.seating_requirement_reviewed
      }
    };
  }

  toggleChecked = (item) => {
    const {
      preDeparture,
      onPreDepartureUpdate
    } = this.props;

    // Get the current state of the checkbox
    const isCurrentlyChecked = this.state.checked[item];

    // If we're toggling from unchecked to checked and the time value is empty
    if (!isCurrentlyChecked && (!preDeparture[item] || preDeparture[item] === '0:00')) {
      // Get current time in HH:MM format
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const currentTime = `${hours}:${minutes}`;

      onPreDepartureUpdate({
        ...preDeparture,
        [item]: currentTime,
      });
    } else if (isCurrentlyChecked) {
      // If we're toggling from checked to unchecked, set value to null
      onPreDepartureUpdate({
        ...preDeparture,
        [item]: null,
      });
    }

    this.setState({
      checked: {
        ...this.state.checked,
        [item]: !isCurrentlyChecked
      }
    });
  }

  render = () => {
    const {
      preDeparture,
      onPreDepartureUpdate
    } = this.props;

    const crewChangeOptions = [
      { label: 'Yes', value: 'yes', selected: preDeparture.crew_change === 'yes' },
      { label: 'No', value: 'no', selected: preDeparture.crew_change === 'no' }
    ];

    const aircraftSwapOptions = [
      { label: 'No', value: 'no', selected: preDeparture.aircraft_swap === 'no' },
      { label: 'Yes - within 3h of ETD/STD', value: 'yes_within_3h', selected: preDeparture.aircraft_swap === 'yes_within_3h' },
      { label: 'Yes - more than 3h of ETD/STD', value: 'yes_more_than_3h', selected: preDeparture.aircraft_swap === 'yes_more_than_3h' }
    ];

    return (
      <FormWrapper>
        <FormSection>
          <FormRow>
            <InputGroup>
              <Label>Crew change</Label>
              <RadioSelect
                options={crewChangeOptions}
                vertical={true}
                onChange={(options) => {
                  const selectedOption = options.find(option => option.selected);
                  onPreDepartureUpdate({
                    ...preDeparture,
                    crew_change: selectedOption ? selectedOption.value : null
                  });
                }}
              />
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup>
              <Label>WCHR Inbound</Label>
              <Input
                narrow
                value={preDeparture.wchr_inbound || ''}
                onChange={(e) => {
                  onPreDepartureUpdate({
                    ...preDeparture,
                    wchr_inbound: parseInt(e.target.value) || ''
                  });
                }}
                placeholder="0"
                type="number"
              />
            </InputGroup>
            <InputGroup>
              <Label>WCHR Outbound</Label>
              <Input
                narrow
                value={preDeparture.wchr_outbound || ''}
                onChange={(e) => {
                  onPreDepartureUpdate({
                    ...preDeparture,
                    wchr_outbound: parseInt(e.target.value) || ''
                  });
                }}
                placeholder="0"
                type="number"
              />
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup horizontal>
              <Label>
                <Checkbox
                  checked={this.state.checked.aircraft_visual_reg_check}
                  onChange={() => { this.toggleChecked('aircraft_visual_reg_check'); }}
                />
                <span>Aircraft visual registration check complete</span>
                <TimeField
                  value={preDeparture.aircraft_visual_reg_check || '0:00'}
                  onChange={(_, timeString) => {
                    onPreDepartureUpdate({
                      ...preDeparture,
                      aircraft_visual_reg_check: timeString,
                    })
                  }}
                  input={
                    <Input
                      placeholder={"Time (off)"}
                      disabled={!this.state.checked.aircraft_visual_reg_check}
                    />
                  }
                />
              </Label>
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup horizontal>
              <Label>
                <Checkbox
                  checked={this.state.checked.seat_map_exit_rows}
                  onChange={() => { this.toggleChecked('seat_map_exit_rows'); }}
                />
                <span>Seat map and exit rows match aircraft type</span>
                <TimeField
                  value={preDeparture.seat_map_exit_rows || '0:00'}
                  onChange={(_, timeString) => {
                    onPreDepartureUpdate({
                      ...preDeparture,
                      seat_map_exit_rows: timeString,
                    })
                  }}
                  input={
                    <Input
                      placeholder={"Time (off)"}
                      disabled={!this.state.checked.seat_map_exit_rows}
                    />
                  }
                />
              </Label>
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup horizontal>
              <Label>
                <Checkbox
                  checked={this.state.checked.zones_correct}
                  onChange={() => { this.toggleChecked('zones_correct'); }}
                />
                <span>Zones are correct</span>
                <TimeField
                  value={preDeparture.zones_correct || '0:00'}
                  onChange={(_, timeString) => {
                    onPreDepartureUpdate({
                      ...preDeparture,
                      zones_correct: timeString,
                    })
                  }}
                  input={
                    <Input
                      placeholder={"Time (off)"}
                      disabled={!this.state.checked.zones_correct}
                    />
                  }
                />
              </Label>
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup horizontal>
              <Label>
                <Checkbox
                  checked={this.state.checked.mandatory_briefing}
                  onChange={() => { this.toggleChecked('mandatory_briefing'); }}
                />
                <span>Mandatory briefing complete</span>
                <TimeField
                  value={preDeparture.mandatory_briefing || '0:00'}
                  onChange={(_, timeString) => {
                    onPreDepartureUpdate({
                      ...preDeparture,
                      mandatory_briefing: timeString,
                    })
                  }}
                  input={
                    <Input
                      placeholder={"Time (off)"}
                      disabled={!this.state.checked.mandatory_briefing}
                    />
                  }
                />
              </Label>
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup>
              <Label>Did an aircraft type swap occur?</Label>
              <RadioSelect
                options={aircraftSwapOptions}
                vertical={true}
                onChange={(options) => {
                  const selectedOption = options.find(option => option.selected);
                  onPreDepartureUpdate({
                    ...preDeparture,
                    aircraft_swap: selectedOption ? selectedOption.value : null
                  });
                }}
              />
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup horizontal>
              <Label>
                <Checkbox
                  checked={this.state.checked.ats_event_reviewed}
                  onChange={() => { this.toggleChecked('ats_event_reviewed'); }}
                />
                <span>ATS event reviewed</span>
                <TimeField
                  value={preDeparture.ats_event_reviewed || '0:00'}
                  onChange={(_, timeString) => {
                    onPreDepartureUpdate({
                      ...preDeparture,
                      ats_event_reviewed: timeString,
                    })
                  }}
                  input={
                    <Input
                      placeholder={"Time (off)"}
                      disabled={!this.state.checked.ats_event_reviewed}
                    />
                  }
                />
              </Label>
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup horizontal>
              <Label>
                <Checkbox
                  checked={this.state.checked.seating_requirement_reviewed}
                  onChange={() => { this.toggleChecked('seating_requirement_reviewed'); }}
                />
                <span>Seating requirement reviewed</span>
                <TimeField
                  value={preDeparture.seating_requirement_reviewed || '0:00'}
                  onChange={(_, timeString) => {
                    onPreDepartureUpdate({
                      ...preDeparture,
                      seating_requirement_reviewed: timeString,
                    })
                  }}
                  input={
                    <Input
                      placeholder={"Time (off)"}
                      disabled={!this.state.checked.seating_requirement_reviewed}
                    />
                  }
                />
              </Label>
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup>
              <Label>Reviewed in JODI by</Label>
              <Input
                value={preDeparture.reviewed_in_jodi_by || ''}
                onChange={(e) => {
                  onPreDepartureUpdate({
                    ...preDeparture,
                    reviewed_in_jodi_by: e.target.value
                  });
                }}
                placeholder="Name"
                style={{ width: '300px' }}
              />
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup>
              <Label>JODI resolved by</Label>
              <Input
                value={preDeparture.jodi_resolved_by || ''}
                onChange={(e) => {
                  onPreDepartureUpdate({
                    ...preDeparture,
                    jodi_resolved_by: e.target.value
                  });
                }}
                placeholder="Name"
                style={{ width: '300px' }}
              />
            </InputGroup>
          </FormRow>
        </FormSection>
      </FormWrapper>
    )
  };

}

export default PreDepartureReport
