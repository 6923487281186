import React from 'react';
import {ButtonWrapper} from "./style";
import loadingSrc from "../../../../images/loading.gif"
import {withTheme} from "styled-components";
import PropTypes from "prop-types";

const Button = ({children, loading, theme, ...props}) => {
  // Ensure loading is a boolean value for DOM attributes
  const isLoading = loading === true;
  
  // Extract loading prop from what's passed to the DOM
  const domProps = {...props};
  
  return (
    <ButtonWrapper {...domProps} theme={theme} loading={isLoading ? 'true' : undefined}>
      {
        !isLoading && children
      }
      {
        isLoading &&
        <img width={theme.size5} src={loadingSrc} alt="Loading"/>
      }
    </ButtonWrapper>
  )
};

Button.defaultProps = {
  loading: false,
};
Button.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default withTheme(Button);
