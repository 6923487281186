import React, { Component } from 'react';
import PromptBox from "../../../common/templates/PromptBox";
import MenuTab from "../../../common/atoms/MenuTab";
import { MenuHeader, ReportBody, ReportHead, } from "./style";
import Button from "../../../common/atoms/Button";
import PTSReport from "./PTSReport";
import PreDepartureReport from "./PreDepartureReport";
import FlightDetailReport from "./FlightDetailReport";
import CommentsReport from "./CommentsReport";
import { getFlightNumber } from "../../../../utils/flight-utils";
import { TYPE } from "../../../../constants/Notice";
import moment from "moment";
import merge from 'lodash/merge';
import { withFlightDepartureReport } from '../../../../hocs/withFlightDepartureReport';
import { getProcessedNodes, getRemainingPtsSteps } from '../../../../utils/notice-utils';

class DepartureReport extends Component {

  steps = [
    "Details",
    // "PAX",
    "Pre-Departure",
    "PTS",
    "Comments"
  ];

  constructor(props) {
    super(props);

    const {
      flight,
      scheduleChannel,
      departureReport,
    } = this.props;

    // Ensure scheduleChannel and its properties exist
    const messagesNodes = scheduleChannel?.messages?.nodes || [];

    const ptsReport = buildPtsReport(flight, messagesNodes);

    const ptsAddedEvents = messagesNodes.filter(node =>
      node && [TYPE.EVENT, TYPE.ESCALATION].includes(node.messageType)
    ).map(event => ({
      messageType: event.messageType,
      content: event.content,
      createAt: event.created_at,
      completedAt: event.completed_at,
      isCompletable: event.is_completable,
    }));

    const defaultReportData = {
      details: {
        gate: flight.departure_gate,
        bay: null,
        tug: null,
        pcu_ext: null,
        oscar: null,
        team_members: null,
        boarding_controller: null,
      },
      passengers: {
        total_joiners: null,
        wchr: null,
        maas: null,
        umnr: null,
        hilift_meda: null,
        deportee: null,
      },
      pre_departure: {
        usa_security_pre_screen: null,
        cleaning_complete: null,
        catering_complete: null,
      },
      pts: ptsReport,
      ptsAddedEvents: ptsAddedEvents,
      comments: null,
    };

    this.state = {
      currentStep: 0,
      checked: false,
      reportData: mergeReportData(departureReport, defaultReportData),
    };
  }

  render = () => {

    const {
      flight,
      loading,
      loadingReportCompletion,
    } = this.props;

    return (
      <PromptBox>
        <ReportHead>
          Departure Report - {getFlightNumber(flight)}
        </ReportHead>
        <ReportBody>
          <MenuHeader>
            {
              this.steps.map((step, i) =>
                <MenuTab key={i}
                  active={i === this.state.currentStep}
                  onClick={() => this.goToStep(i)}>
                  {step}
                </MenuTab>
              )
            }
          </MenuHeader>
          {
            this.renderFormBody()
          }
          {
            this.renderActionButton()
          }
          <Button basic
            disabled={loading && loadingReportCompletion}
            loading={loading && !loadingReportCompletion}
            onClick={() => this.saveDepartureReport(false)}>Save & Close</Button>
        </ReportBody>
      </PromptBox>
    )
  };

  goToStep = (step) => {
    this.setState({
      currentStep: step,
    })
  };

  renderFormBody = () => {
    const {
      flight,
    } = this.props;

    switch (this.state.currentStep) {
      case 0:
        return <FlightDetailReport flight={flight}
          details={this.state.reportData.details}
          onDetailsUpdate={(newDetails) => this.setState({
            reportData: {
              ...this.state.reportData,
              details: newDetails
            }
          })} />;
      // case 1:
      //   return <PassengersReportData flight={flight}
      //                                passengers={this.state.reportData.passengers}
      //                                onPassengersUpdate={(newPassengers) => this.setState({
      //                                  reportData: {
      //                                    ...this.state.reportData,
      //                                    passengers: newPassengers
      //                                  }
      //                                })}/>;
      case 1:
        return <PreDepartureReport preDeparture={this.state.reportData.pre_departure}
          onPreDepartureUpdate={(newPreDeparture) => this.setState({
            reportData: {
              ...this.state.reportData,
              pre_departure: newPreDeparture
            }
          })} />;
      case 2:
        return <PTSReport flight={flight}
          ptsList={this.state.reportData.pts}
          onPTSUpdate={(newPTS) => this.setState({
            reportData: {
              ...this.state.reportData,
              pts: newPTS
            }
          })} />;
      case 3:
        return <CommentsReport comments={this.state.reportData.comments}
          ptsAddedEvents={this.state.reportData.ptsAddedEvents}
          timezoneOffset={flight.departure_airport.timezone_offset}
          onCommentsUpdate={(newComments) => this.setState({
            reportData: {
              ...this.state.reportData,
              comments: newComments
            }
          })}
          onBoardingCommentsUpdate={(newBoardingComments) => this.setState({
            reportData: {
              ...this.state.reportData,
              ptsAddedEvents: newBoardingComments
            }
          })}
        />;
      default:
        return (<></>);
    }
  };

  renderActionButton = () => {
    const {
      loading,
    } = this.props;

    if (this.state.currentStep < this.steps.length - 1) {
      return (
        <Button primary
          fluid
          disabled={loading}
          onClick={() => this.goToStep(this.state.currentStep + 1)}>
          Next
        </Button>
      )
    }
    // else {
    //   return (
    //     <Button primary
    //             fluid
    //             disabled={loading && !loadingReportCompletion}
    //             loading={loading && loadingReportCompletion}
    //             onClick={() => this.saveDepartureReport(true)}>
    //       Submit
    //     </Button>
    //   )
    // }
  };

  saveDepartureReport = (complete) => {
    const {
      onConfirm,
    } = this.props;

    const reportData = this.validateData();
    onConfirm(complete, reportData);
  };

  validateData = () => {
    let reportData = this.state.reportData;

    // Debugging - log the full report data before processing
    console.log('Pre-validation Report Data:', JSON.parse(JSON.stringify(reportData)));

    // Inspect comments field specifically
    console.log('Comments field value:', reportData.comments);
    console.log('Comments field type:', typeof reportData.comments);

    reportData.pts = reportData.pts.map(pts => {
      delete pts["hour"];
      delete pts["minute"];
      return pts;
    });

    // Try to parse the comments JSON if it's a string
    try {
      if (typeof reportData.comments === 'string' && reportData.comments) {
        const parsed = JSON.parse(reportData.comments);
        console.log('Successfully parsed comments JSON:', parsed);
      }
    } catch (e) {
      console.error('Error parsing comments JSON:', e);
      // Don't modify the data, just log the error
    }

    // Debugging - log the processed report data
    console.log('Post-validation Report Data:', JSON.parse(JSON.stringify(reportData)));

    return reportData
  }
}

function mergeReportData(savedReportData, defaultReportData) {
  const existingReportJson = savedReportData?.report

  if (!existingReportJson) {
    return defaultReportData;
  }

  const existingReportData = JSON.parse(existingReportJson);

  const {
    details,
    // passengers,
    pre_departure,
    pts,
    comments,
    ptsAddedEvents,
  } = existingReportData;

  // No parsing needed - ptsAddedEvents is just a string
  const existingFormReportData = {
    details,
    // passengers,
    pre_departure,
    comments,
    ptsAddedEvents,
  }

  const reportData = merge({}, defaultReportData, existingFormReportData);
  reportData.pts = mergeEventTimeline(pts, defaultReportData.pts)

  return reportData
}

function mergeEventTimeline(savedEvents, defaultEvents) {
  const mergedEvents = [...defaultEvents]; // TODO deep clone?

  savedEvents.forEach((savedEvent) => {
    let ptsReportEvent = mergedEvents.find(e => e.id === savedEvent.id);

    // TODO remove this block in future
    if (!savedEvent.id) {
      // Assume it's an old departure report predating use of IDs
      ptsReportEvent = mergedEvents.find(e => {
        if (savedEvent.code) {
          return savedEvent.code === e.code;
        }
        return (savedEvent.code === e.code && savedEvent.description === e.description);
      });
    }

    if (ptsReportEvent) {
      savedEvent.actual_time && (ptsReportEvent.actual_time = savedEvent.actual_time);
      savedEvent.actual_delta && (ptsReportEvent.actual_delta = savedEvent.actual_delta);
    }
  });

  return mergedEvents;
}

function buildReportEvent(flight, event, eventTemplate = {}) {
  const scheduledTime = (typeof eventTemplate.timing === 'number') ? moment(flight.offblocks_latest_estimate).add(eventTemplate.timing, "minutes").utc().format() : null;
  const actualTime = event && !event.completed_skipped ? event.completed_at : null;

  const reportEvent = {
    id: event ? event.id : null,
    code: eventTemplate.code || null,
    description: event ? event.content : eventTemplate.description,
    scheduled_time: scheduledTime,
    scheduled_delta: eventTemplate.timing,
    actual_time: actualTime,
    actual_delta: actualTime ? Math.floor(moment.duration(moment(actualTime).diff(moment(flight.offblocks_latest_estimate))).asMinutes()) : null,
  }

  event && event.deleted_at && (reportEvent.deleted_at = event.deleted_at);

  return reportEvent;
}

function buildPtsReport(flight, nodes) {
  if (!flight || !Array.isArray(nodes)) {
    console.warn('Invalid arguments to buildPtsReport', { flight, nodes });
    return [];
  }

  let isActuallyOffBlocks;

  const ptsSchedule = flight.pts || [];
  const ptsActualEvents = getProcessedNodes(flight, nodes, [TYPE.PTS, TYPE.EVENT, TYPE.ESCALATION]) || [];
  const ptsRemainingEvents = getRemainingPtsSteps(flight, nodes) || [];
  const offBlocksEventTemplate = {
    code: 'OFF_BLOCKS',
    description: 'Off Blocks',
    timing: 0,
  };

  const reportActualEvents = ptsActualEvents.map(event => {
    if (event.messageType === TYPE.PTS && event.content === 'Off Blocks') {
      isActuallyOffBlocks = true;
      return buildReportEvent(flight, event, {...offBlocksEventTemplate, content: event.content});
    }

    if (event.messageType === TYPE.PTS) {
      const eventTemplate = ptsSchedule.find(e => e.code === event.completes_pts_workflow_status);
      return buildReportEvent(flight, event, eventTemplate);
    }

    if (event.messageType === TYPE.EVENT) {
      const updatedEvent = { ...event };
      !updatedEvent.is_completable && (updatedEvent.completed_at = event.created_at); // Use created_at for custom events that aren't completable
      return buildReportEvent(flight, updatedEvent);
    }

    if (event.messageType === TYPE.ESCALATION) {
      return buildReportEvent(flight, event);
    }

    return null;
  }).filter(e => e); // Remove null and undefined

  const reportRemainingEvents = ptsRemainingEvents.map(event => buildReportEvent(flight, null, event));

  // Check if OFF_BLOCKS already exists in reportActualEvents before adding it
  const hasOffBlocksEvent = reportActualEvents.some(event => event.code === 'OFF_BLOCKS');
  
  if (!isActuallyOffBlocks && !hasOffBlocksEvent) {
    reportRemainingEvents.push(buildReportEvent(flight, null, offBlocksEventTemplate));
  }

  return [...reportActualEvents, ...reportRemainingEvents];
};

export default withFlightDepartureReport(DepartureReport);
