import React, {Component} from 'react';
import PropTypes from "prop-types";
import Modal from "../../../../../common/molecules/Modal";
import MutatedDepartureReport from "../../../DepartureReport/mutated";
import FlightType from "../../../../../../constants/Flight";
import ChannelType from '../../../../../../constants/Channel';

class DepartureReportModal extends Component {
  state = {
    showModal: false,
  };

  render = () => {
    const {
      flight,
      scheduleChannel,
      trigger,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.showModal}
        onOpen={this.openModal}
        onClose={this.handleCloseWithSave}
        closeOnDimmerClick={true}
        fixed={true}
        trigger={
          trigger
        }
      >
        <MutatedDepartureReport
          flight={flight}
          scheduleChannel={scheduleChannel}
          onClose={this.closeModal}
        />
      </Modal>
    )
  };

  openModal = () => this.setState({showModal: true});

  closeModal = () => this.setState({showModal: false});
  
  handleCloseWithSave = () => {
    // First try to find Save & Close button from active modal content
    const activeModals = document.querySelectorAll('.ModalBackground[isOpen="true"]');
    if (activeModals.length) {
      // Look within the most recently opened modal (last in DOM)
      const activeModal = activeModals[activeModals.length - 1];
      
      // Find buttons within the modal content
      const buttons = activeModal.querySelectorAll('button');
      
      // First try: find by text content
      for (const button of buttons) {
        const buttonText = button.textContent || '';
        if (buttonText.includes('Save & Close')) {
          button.click();
          return; // Found and clicked the button
        }
      }
      
      // Second try: find by the basic attribute (our button is a basic button)
      const basicButtons = activeModal.querySelectorAll('button[basic]');
      if (basicButtons.length) {
        basicButtons[0].click();
        return; // Found and clicked the button
      }
    }
    
    // If all else fails, try a document-wide search for the Save & Close button
    const allButtons = document.querySelectorAll('button');
    const saveButton = Array.from(allButtons).find(button => {
      return button.textContent && button.textContent.includes('Save & Close');
    });
    
    if (saveButton) {
      saveButton.click();
    } else {
      // If we get here, we couldn't find the modal or the button, just close
      this.closeModal();
    }
  };
}

DepartureReportModal.propTypes = {
  flight: FlightType.isRequired,
  scheduleChannel: ChannelType.isRequired,
  trigger: PropTypes.node,
};

export default DepartureReportModal;